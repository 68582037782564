﻿dropbucket {
    right: 20px;
    bottom: 20px;
    position: fixed;
    z-index: $z-index-base;

    .btn-dismiss {
        padding: 0;
        margin: 0;
        top: 0;
        right: 0;
    }

    .droppedcontent {
        @include surface();
        @include animation-fadein();
        position: relative;
        border-radius: $toolbox-border-radius;
        display: flex;
        flex-direction: column;
    }

    .droppedcontent-details {
        @include text-overflow();
    }

    .droppedcontent-preview {
        width: 280px;
        height: 150px;
        background-image: data-uri("../svgs/icons-unknown.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: block;
        margin: 10px 0;
    }

    .list-item {
        position: relative;
    }

    .btn {
        height: 100%;
    }
}
