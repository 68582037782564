﻿$tip-width: 10px;

.balloon {
    @include surface();
    @include animation-fadein(0.3s);
    position: fixed;
    background-color: $balloon-background;
    z-index: $z-index-balloon;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: $toolbox-border-radius;
    padding: 7px;
    display: none;

    &.balloon-is-active {
        display: flex;
    }
}

.balloon-tip {
    position: fixed;
    background-color: $balloon-background;
    width: $tip-width;
    height: $tip-width;
    z-index: $z-index-balloon;
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.15);
    @include animation-fadein(0.3s);

    &.balloon-top {
        transform: rotate(45deg);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    }

    &.balloon-bottom {
        transform: rotate(45deg);
        box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.15);
    }

    &.balloon-left {
        transform: rotate(-45deg);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    }

    &.balloon-right {
        transform: rotate(-45deg);
        box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.15);
    }
}
