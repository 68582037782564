.pickr {
  position: relative;
  overflow: visible;
}

.pickr * {
  box-sizing: border-box;
}

.pickr .pcr-button {
  display: none;
  position: relative;
  height: 2em;
  width: 100%;
  padding: .5em;
  border-radius: .15em;
  cursor: pointer;
  background: transparent;
  transition: background-color .3s;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
}

.pickr .pcr-button:before {
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 6px;
  border-radius: .15em;
  z-index: -1;
}

.pickr .pcr-button:after,
.pickr .pcr-button:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pickr .pcr-button:after {
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" stroke="%2342445A" stroke-width="5px" stroke-linecap="round"><path d="M45,45L5,5"></path><path d="M45,5L5,45"></path></svg>') no-repeat 50%;
  background-size: 70%;
  opacity: 0;
}

.pickr .pcr-button.clear:after {
  opacity: 1;
}

.pickr .pcr-button.disabled {
  cursor: not-allowed;
}

.pcr-app {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
  top: 5px;
  height: 15em;
  width: 100%;
  max-width: 95vw;
  border-radius: .1em;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s;
}

.pcr-app.visible {
  visibility: visible;
  opacity: 1;
}

.pcr-app .pcr-interaction {
  display: flex;
  align-items: center;
  margin: 1em -.2em 0;
}

.pcr-app .pcr-interaction > * {
  margin: 0 .2em;
}

.pcr-app .pcr-interaction input {
  padding: .5em .6em;
  border: none;
  letter-spacing: .07em;
  font-size: .75em;
  text-align: center;
  cursor: pointer;
  transition: all .15s;
}

.pcr-app .pcr-interaction .pcr-result {
  text-align: left;
  flex-grow: 1;
  min-width: 1em;
  cursor: text;
  padding-left: .8em;
  @extend .form-control;
}

.pcr-app .pcr-interaction .pcr-result:focus {
  color: #21447f;
}

.pcr-app .pcr-interaction .pcr-result::selection {
  background: #21447f;
  color: #fff;
}

.pcr-app .pcr-interaction .pcr-type {
  background: none;
}

.pcr-app .pcr-interaction .pcr-type.active {
  color: #fff;
  background: #21447f;
}

.pcr-app .pcr-interaction .pcr-clear,
.pcr-app .pcr-interaction .pcr-save {
  color: #fff;
  width: auto;
}

.pcr-app .pcr-interaction .pcr-save {
  background: #21447f;
}

.pcr-app .pcr-interaction .pcr-save:hover {
  background: #4370f4;
  color: #fff;
}

.pcr-app .pcr-interaction .pcr-clear {
  background: #f44250;
}

.pcr-app .pcr-interaction .pcr-clear:hover {
  background: #db3d49;
  color: #fff;
}

.pcr-app .pcr-selection {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.pcr-app .pcr-selection .pcr-picker {
  position: absolute;
  height: 18px;
  width: 18px;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  cursor: -moz-grab;
  cursor: -webkit-grabbing;
  z-index: 100;
}

.pcr-app .pcr-selection .pcr-color-preview {
  position: relative;
  z-index: 1;
  width: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pcr-app .pcr-selection .pcr-color-preview:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 6px;
  border-radius: .15em;
  z-index: -1;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview .pcr-last-color {
  cursor: pointer;
  transition: background-color .3s,box-shadow .3s;
  border-radius: .15em .15em 0 0;
  z-index: 2;
}

.pcr-app .pcr-selection .pcr-color-preview .pcr-current-color {
  border-radius: 0 0 .15em .15em;
}


.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview .pcr-current-color,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview .pcr-last-color {
  background: currentColor;
  width: 100%;
  height: 50%;
  border: none;
}

.pcr-app .pcr-selection .pcr-color-chooser,
.pcr-app .pcr-selection .pcr-color-opacity,
.pcr-app .pcr-selection .pcr-color-palette {
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.pcr-app .pcr-selection .pcr-color-palette {
  width: 80%;
  z-index: 1;
}

.pcr-app .pcr-selection .pcr-color-palette .pcr-palette {
  height: 100%;
  border-radius: .15em;
}

.pcr-app .pcr-selection .pcr-color-palette .pcr-palette:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 6px;
  border-radius: .15em;
  z-index: -1;
}

.pcr-app .pcr-selection .pcr-color-chooser .pcr-picker,
.pcr-app .pcr-selection .pcr-color-opacity .pcr-picker {
  left: 50%;
  transform: translateX(-50%);
}

.pcr-app .pcr-selection .pcr-color-chooser .pcr-slider,
.pcr-app .pcr-selection .pcr-color-opacity .pcr-slider {
  width: 8px;
  height: 100%;
  border-radius: 50em;
}

.pcr-app .pcr-selection .pcr-color-chooser .pcr-slider {
  background: linear-gradient(180deg,red,#ff0,#0f0,#0ff,#00f,#f0f,red);
}

.pcr-app .pcr-selection .pcr-color-opacity .pcr-slider {
  background: linear-gradient(180deg,transparent,#000),url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
  background-size: 100%,50%;
}

.pickr-dialog {
    min-width: 250px; 
    height: 100%; 
    width: 100%; 
    margin: 7px;
}