﻿.toolbox-dropdown {
    display: inline-block;
    box-sizing: border-box;
    border: none;

    & > span {
        line-height: $toolbox-btn-size;
        padding: 0 10px;
    }

    a {
        text-decoration: none;
    }
}
