.tooltip {
    padding: 10px;
    max-width: 300px;
    display: block;
    line-height: 1.5em;
    p {
        margin: 0;
    }

    h1 {
        margin-bottom: 5px;
    }
}
