﻿@mixin animation-flyin {
    transition: 1s ease-in-out left;
}

@mixin animation-resize {
    transition: height 1s ease;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@mixin animation-fadein($timeframe: .5s) {
    opacity: 0;
    animation: fade-in $timeframe linear forwards;
}

@keyframes shake {
    0% {
        -webkit-transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
    }
}

@mixin animation-shake {
    animation-name: shake;
    animation-duration: 0.8s;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@mixin animation-slideleft {
    animation-name: slideleft;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes slideleft {
    0% {
        transform: translateX(150%);
    }
    50% {
        transform: translateX(-8%);
    }
    65% {
        transform: translateX(4%);
    }
    80% {
        transform: translateX(-4%);
    }
    95% {
        transform: translateX(2%);
    }
    100% {
        transform: translateX(0%);
    }
}

.animation-slideright {
    animation-name: slideright;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes slideright {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(8%);
    }
    65% {
        transform: translateX(-4%);
    }
    80% {
        transform: translateX(4%);
    }
    95% {
        transform: translateX(-2%);
    }
    100% {
        transform: translateX(0%);
    }
}

.animation-popin {
    animation: pop-in .2s ease;
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}