﻿[alignment] {
    position: absolute;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    width: 14px;
    height: 14px;
    display: block;
    cursor: pointer;

    polygon {
        fill: transparent;
        stroke: $color-base;
        stroke-width: 1;
        @include tabable();
    }

    &.active {
        polygon {
            fill: $arrow-active-color;
            stroke: $arrow-active-color;
        }
    }

    &[alignment="top left"] {
        transform: rotate(270deg);
        top: 0;
        left: 0;
    }

    &[alignment="top center"] {
        top: 0;
        left: 50%;
        bottom: initial;
        transform: rotate(317deg);
    }

    &[alignment="top right"] {
        top: 0;
        right: 0;
    }

    &[alignment="center left"] {
        top: 50%;
        left: 0;
        transform: rotate(225deg);
    }

    &[alignment="center right"] {
        right: 0;
        top: 50%;
        transform: rotate(45deg);
    }

    &[alignment="bottom left"] {
        bottom: 0;
        left: 0;
        transform: rotate(180deg);
    }

    &[alignment="bottom center"] {
        bottom: 0;
        transform: rotate(135deg);
        left: 50%;
    }

    &[alignment="bottom right"] {
        bottom: 0;
        right: 0;
        transform: rotate(90deg);
    }
}

.dropzone {
    margin: 10px 0;

    .dropzone-controls {
        padding: 12px;
    }

    .dropzone-showbox {
        width: 100%;
        height: 150px;
        position: relative;
        display: block;
        border: 1px solid $form-control-border;
        @include tabable();
        @include pseudo-transparent-bckg();
    }

    .dropzone-footer {
        display: block;
        height: 1.5em;
    }
}

.dropzone-square {
    width: 60px;
    height: 60px;
    outline: 1px solid $form-control-border;
    outline-offset: 4px;
    margin: 6px;
}
