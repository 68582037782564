.widget-icon {
    width: 34px;
    height: 21px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin-bottom: 7px;
}

.widget-icon-accordion {
    background-image: url("./icons/icon-accordion.svg");
}

.widget-icon-button {
    background-image: url("./icons/icon-button.svg");
}

.widget-icon-card {
    background-image: url("./icons/icon-card.svg");
}

.widget-icon-carousel {
    background-image: url("./icons/icon-carousel.svg");
}

.widget-icon-checkbox {
    background-image: url("./icons/icon-checkbox.svg");
}

.widget-icon-collapsible-panel {
    background-image: url("./icons/icon-collapsible-panel.svg");
}

.widget-icon-component {
    background-image: url("./icons/icon-component.svg");
}

.widget-icon-date-input {
    background-image: url("./icons/icon-date-input.svg");
}

.widget-icon-email-input {
    background-image: url("./icons/icon-email-input.svg");
}

.widget-icon-form {
    background-image: url("./icons/icon-form.svg");
}

.widget-icon-map {
    background-image: url("./icons/icon-map.svg");
}

.widget-icon-menu {
    background-image: url("./icons/icon-menu.svg");
}

.widget-icon-multi-line-input {
    background-image: url("./icons/icon-multi-line-input.svg");
}

.widget-icon-number-input {
    background-image: url("./icons/icon-number-input.svg");
}

.widget-icon-password-input {
    background-image: url("./icons/icon-password-input.svg");
}

.widget-icon-picture-gallery {
    background-image: url("./icons/icon-picture-gallery.svg");
}

.widget-icon-picture {
    background-image: url("./icons/icon-picture.svg");
}

.widget-icon-range-input {
    background-image: url("./icons/icon-range-input.svg");
}

.widget-icon-search-box {
    background-image: url("./icons/icon-search-box.svg");
}

.widget-icon-select-input {
    background-image: url("./icons/icon-select-input.svg");
}

.widget-icon-splitter {
    background-image: url("./icons/icon-splitter.svg");
}

.widget-icon-submit-form-button {
    background-image: url("./icons/icon-submit-form-button.svg");
}

.widget-icon-tab-panel {
    background-image: url("./icons/icon-tab-panel.svg");
}

.widget-icon-table {
    background-image: url("./icons/icon-table.svg");
}

.widget-icon-testimonials {
    background-image: url("./icons/icon-testimonials.svg");
}

.widget-icon-text-block {
    background-image: url("./icons/icon-text-block.svg");
}

.widget-icon-text-input {
    background-image: url("./icons/icon-text-input.svg");
}

.widget-icon-time-input {
    background-image: url("./icons/icon-time-input.svg");
}

.widget-icon-url-input {
    background-image: url("./icons/icon-url-input.svg");
}

.widget-icon-video-player {
    background-image: url("./icons/icon-video-player.svg");
}

.widget-icon-youtube-player {
    background-image: url("./icons/icon-youtube-player.svg");
}

.widget-icon-api-management {
    background-image: url("./icons/icon-api-management.svg");
}