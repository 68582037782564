
.add-block-container {
    width: 100%;
    max-height: 600px;
    .remove-block {
        background-color: rgb(43, 135, 218);
        background-repeat: no-repeat;
        position: absolute;
        top: 10px;
        right: 10px;
        .paperbits-icon {
            padding: 0;
        }
    }
}

.block-item {
    width: 100%;
    height: 120px;
    overflow: hidden;
    margin: 8px 2px 2px 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.block-item-container {
    width: 1300px;
    height: 400px;
    transform: scale(0.28);
    transform-origin: 0% 0%;
    transform-origin: top left;
    pointer-events: none;
    border: none;
    overflow: hidden;
}