.fonts {
    list-style: none;
    padding: 0;
    margin: 0;
}

.font {
    font-size: 20px;
    text-decoration: none;
    color: $color-base;
    padding: 5px 10px;

    &.font-italic {
        font-style: italic;
    }

    &.font-thin {
        font-weight: 100;
    }

    &.font-extra-light {
        font-weight: 200;
    }

    &.font-light {
        font-weight: 300;
    }

    &.font-regular {
        font-weight: 400;
    }

    &.font-medium {
        font-weight: 500;
    }

    &.font-semi-bold {
        font-weight: 600;
    }

    &.font-bold {
        font-weight: 700;
    }

    &.font-extra-bold {
        font-weight: 800;
    }

    &.font-black {
        font-weight: 900;
    }
}
