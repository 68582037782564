﻿.workshop {
    .workshop-journey-step {
        min-width: 300px;
        padding: 0 20px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &:not(:last-child) {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 40px;
                bottom: 40px;
                right: 0;
                width: 1px;
                border: 0;
                background: $workshop-journey-step-border-color;
                background: radial-gradient($workshop-journey-step-border-color, white);
            }
        }

        &:first-child {
            @extend .flex-item-grow;
        }

        h1 {
            padding-left: 5px;
            line-height: 25px;
        }

        .btn-dismiss {
            top: 0;
            right: 0;
        }
    }
}

.toolbox.workshops-container {
    z-index: $z-index-base;
    min-height: 370px;

    .journey-container {
        padding: 10px 20px;
    }
}

.workshop {
    padding: 10px;
    @media (min-width: $breakpoint-lg) {
        width: 80%;
    }
}

workshops {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    justify-content: center;
}

.workshop:first-child {
    margin-left: 0;
}

.workshop:last-child {
    margin-right: 0;
}
