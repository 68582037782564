@use "sass:math";

$box-model-spacing: 30px;
$box-model-spacing-from-border: 1px;
$box-model-border-width: 4px;

.box-model {
    font-family: monospace;

    .box-model-control {
        border: none;
        position: absolute;
        width: $box-model-spacing - 2px;
        text-align: center;
        background: transparent;
        font-family: monospace;
        border: 1px dashed #929292;

        &:focus {
            outline: 1px solid #ccc;
        }
    }

    .box-model-control-top {
        top: $box-model-spacing-from-border;
        left: 50%;
        transform: translateX(-50%);
    }

    .box-model-control-left {
        left: $box-model-spacing-from-border;
        top: 50%;
        transform: translateY(-50%);
    }

    .box-model-control-right {
        right: $box-model-spacing-from-border;
        top: 50%;
        transform: translateY(-50%);
    }

    .box-model-control-bottom {
        bottom: $box-model-spacing-from-border;
        left: 50%;
        transform: translateX(-50%);
    }

    .box-model-control-top-left {
        top: math.div(-$box-model-border-width, 2);
        left: math.div(-$box-model-border-width, 2);
    }

    .box-model-control-top-right {
        top: math.div(-$box-model-border-width, 2);
        right: math.div(-$box-model-border-width, 2);
    }

    .box-model-control-bottom-left {
        bottom: math.div(-$box-model-border-width, 2);
        left: math.div(-$box-model-border-width, 2);
    }

    .box-model-control-bottom-right {
        bottom: math.div(-$box-model-border-width, 2);
        right: math.div(-$box-model-border-width, 2);
    }

    .box-model-group {
        @include fit;
        display: none;
    }

    .box-model-enabled {
        > .box-model-group {
            display: block;
        }
    }

    .box-model-margin {
        &.box-model-enabled {
            padding: 20px 30px;
        }

        > .box-model-group {
            border: 1px dashed #d8e60a;
        }
    }

    .box-model-padding {
        &.box-model-enabled {
            padding: $box-model-spacing;
            margin: 5px;

            > .box-model-group {
                border: 1px dotted #ccc;
            }
        }
    }

    .box-model-content {
        border: 1px dashed #54a9ff;
        min-width: 80px;
        height: 60px;
        text-align: center;
        line-height: 60px;
    }

    .box-model-label {
        position: absolute;
        top: 0;
        left: 0;
        line-height: $box-model-spacing;
        padding: 0 5px;
    }

    .box-model-border {
        &.box-model-enabled {
            padding: 20px;
            margin: 20px;

            > .box-model-group {
                border: $box-model-border-width solid #737373;
                position: absolute;
                top: -4px;
                bottom: -4px;
                left: -4px;
                right: -4px;

                & > .box-model-control {
                    background: #737373;
                    border-radius: 4px;
                    color: #fff;

                    &.box-model-control-top {
                        top: math.div(-$box-model-border-width, 2);
                        transform: translate(-50%, -50%);
                    }

                    &.box-model-control-left {
                        left: math.div(-$box-model-border-width, 2);
                        transform: translate(-50%, -50%);
                    }

                    &.box-model-control-right {
                        right: math.div(-$box-model-border-width, 2);
                        transform: translate(50%, -50%);
                    }

                    &.box-model-control-bottom {
                        bottom: math.div(-$box-model-border-width, 2);
                        transform: translate(-50%, 50%);
                    }

                    &.box-model-control-top-left {
                        transform: translate(-50%, -50%);
                    }

                    &.box-model-control-top-right {
                        transform: translate(50%, -50%);
                    }

                    &.box-model-control-bottom-left {
                        transform: translate(-50%, 50%);
                    }

                    &.box-model-control-bottom-right {
                        transform: translate(50%, 50%);
                    }
                }
            }
        }
    }
}
