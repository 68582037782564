﻿@use "sass:math";

paperbits-code,
paperbits-googlemaps,
paperbits-picture,
paperbits-text,
widget {
    display: block;

    &.dragged {
        box-shadow: 2px 2px 2px 2px rgba(128, 128, 128, 0.4);
        transform: scale(1.05);
        transition: transform 0.5s ease-in-out, height 0.5s ease-in-out, width 0.5s ease-in-out;
        background: #fff;
        opacity: 0.98;
        position: fixed;
    }

    &:hover {
        @include selection(10px);
    }
}

.layout-editor-selection {
    position: fixed;
    pointer-events: none;
    z-index: $z-index-selection;

    &:before {
        content: attr(title);
        background: $layout-editor-selection-color;
        color: #fff;
        padding: 5px;
        left: -5px;
        top: -30px;
        position: absolute;
        font-size: 8px;
        text-transform: uppercase;
        font-family: $font-family-base;
        opacity: 0.7;
    }

    &:after {
        @include fit;
        content: "";
        display: block;
        position: absolute;
    }
}

.layout-editor-splitter {
    position: fixed;
    pointer-events: none;
    z-index: $z-index-selection;
    border-color: $layout-editor-splitter-color;
    border-style: dashed;
    border-width: 0;
    opacity: 0.5;
}

.empty-item {
    margin: auto;
    width: 50%;
    text-align: center;
}

.fixed {
    position: fixed;
    z-index: $z-index-base;
}

.btn-standalone {
    color: #ececec;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: $z-index-selection + 1;
    line-height: 1em;

    .paperbits-icon {
        font-size: 0.9em;
    }

    &:hover {
        transform: scale(1.2);
        transition-duration: 0.3s;
        z-index: $z-index-selection + 2;
    }
}

.row-cfg {
    border-radius: 7px;
    border-spacing: 2px;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    padding: 0;
    line-height: 0;
    width: 80px;
    white-space: nowrap;
    margin: 5px;
    @include selection(15px, -5px);

    &:hover {
        cursor: pointer;
    }
}

.col-cfg {
    height: 30px;
    width: 70px;
    background: #ccc;
    outline: 1px solid gray;
    flex: 0 1 auto;
    margin: 2px;
}

.col-cfg-3 {
    width: math.div(3 * 100%, 12)
}

.col-cfg-4 {
    width: math.div(4 * 100%, 12)
}

.col-cfg-6 {
    width: math.div(6 * 100%, 12)
}

.col-cfg-8 {
    width: math.div(8 * 100, 12);
}

.col-cfg-9 {
    width: math.div(9 * 100%, 12);
}

.col-cfg-12 {
    width: math.div(12 * 100%, 12)
}

.section-cfg {
    padding: 0;
    margin: 5px;
    display: inline-block;
    border-radius: 7px;
    border: 3px solid $color-base;
    line-height: 0;
    width: 120px;
    cursor: pointer;
    @include selection(15px, -10px);
}

.section-container-cfg {
    margin: 0 15%;
    background: #ece7e7;
    display: block;
    height: 50px;
    border-left: 2px solid $color-base;
    border-right: 2px solid $color-base;
}

.section-fluid-cfg {
    margin: 0;
    background: #ece7e7;
    display: block;
    height: 50px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}
