html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

* {
    @include box-sizing(border-box);
    //position: relative;
}

app * {
    position: relative;
}

*:before,
*:after {
    @include box-sizing(border-box);
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

[role="button"] {
    cursor: pointer;
}
