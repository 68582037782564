.flex {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    min-height: 0; // fixes issue with oveflow in Edge, FF and other browsers

    &.flex-row {
        flex-direction: row;
    }

    &.flex-column {
        flex-direction: column;
    }

    .flex-item {
        flex: 0 1 auto;
        align-self: auto;

        &.flex-item-grow {
            flex: 1 1 auto;
        }
    }

    .flex-wrap {
        flex-wrap: wrap;
    }
}

.flex-end {
    align-items: flex-end;
}
