﻿@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 400;
    src: url('fonts/OpenSans-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 600;
    src: url('fonts/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 900;
    src: url('fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 900;
    src: url('fonts/OpenSans-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'paperbits';
    src: url('fonts/paperbits.eot');
    src: url('fonts/paperbits.eot') format('embedded-opentype'), url('fonts/paperbits.woff2') format('woff2'), url('fonts/paperbits.woff') format('woff'), url('fonts/paperbits.ttf') format('truetype'), url('fonts/paperbits.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}