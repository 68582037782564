.snippet-grid {
  display: grid;
  grid-template-columns: repeat(12, 10px);
  grid-template-rows: repeat(12, 10px);
  row-gap: 0 !important;
  column-gap: 0 !important;
  margin: 5px !important;
  width: 80px !important;
  float: left;

  &:hover {
    @include selection(15px, -5px);
    cursor: pointer;
  }
}

.snippet-grid-cell {
  background: #d9d9d9;
  outline: 1px solid gray;
  min-width: 15px;
  min-height: 20px;
  margin: 2px;

  &[role="article"] {
    background: #bfbfbf;
  }
}
