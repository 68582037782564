﻿.list-item-group {
    width: 100%;

    .list-item {
        flex-basis: unset;
    }

    &.dragged {
        pointer-events: none;
        box-shadow: 0 0 2px 2px rgba(128, 128, 128, 0.4);
        transform: scale(1.07);
        transition: transform 0.1s ease-in-out;
        min-width: 200px;
        position: fixed;
    }

    &.list-item-group-collapsible {
        padding-left: 18px;
    }
}

.list-item-collapsible {
    display: flex;
    align-items: center;

    .list-item-collapse {
        display: block;
        cursor: pointer;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        left: -16px;
        position: absolute;
        @include selection(8px, -3px);

        &:focus,
        &:hover {
            outline: none;
            cursor: pointer;
        }

        .paperbits-icon {
            color: $collapse-button-color;
        }
    }
}

.list {
    width: 300px;
    height: 400px;
}

.list-item {
    line-height: 2.2em;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
    display: flex;
    text-decoration: none;
    color: $color-base;
    padding: 0 5px;
    @include selection(5px);

    .paperbits-icon {
        padding: 0 5px;
    }

    .list-item-label {
        padding: 0 5px;
        flex: 1;
        text-decoration: none;
        color: $color-base;
        @include text-overflow();
    }

    &:focus,
    &:hover {
        outline: none;
    }

    &.selected {
        font-weight: bold;
    }

    .list-item-handle {
        cursor: pointer;
        cursor: grab;
    }

    .list-item-thumbnail {
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 50%;
        background-color: $list-item-thumbnail-bg;
        float: right;
        box-shadow: inset -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    }

    &.list-item-image {
        @include pseudo-transparent-bckg();
        margin: 3px;
        padding: 0;
        flex-basis: auto;
    }

    &.list-item-icon {
        float: left;
        padding: 5px;
        display: block;
        text-align: center;
        line-height: 1em;
        width: 90px;
        height: 90px;

        .icon {
            font-size: 18px;
            padding: 20px;
        }
    }

    .list-item-widget-icon {
        margin: 7px;
    }

    &.list-item-large {
        display: inline-block;
        width: 140px;
        text-align: center;
        padding: 15px 5px;
        line-height: 1.5em;
        margin-bottom: 15px;
        flex-basis: auto;
        vertical-align: top;

        a {
            display: inline-block;
        }

        .paperbits-icon {
            display: block;
            padding: 0;
            height: 1.5em;
        }
    }
}

.list-edge {
    display: block;
    flex-basis: 100%;
    min-height: 10px;
    min-width: 10px;
}

.list-checkboxes {
    .list-item {
        padding-left: 25px;

        &.list-item-checked::before {
            @extend .paperbits-icon;
            @extend .paperbits-check-2;
            position: absolute;
            left: 5px;
        }
    }
}
