.shutter {
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    position: fixed;
    background: $spinner-shutter-bg;
    pointer-events: none;
    transition: opacity .5s ease-in-out;

    spinner {
        display: none;
    }

    &.active {
        opacity: 1;

        spinner {
            display: flex;
        }
    }
}

spinner {
    display: flex;
    text-align: center;
    align-items: center;
    flex-basis: 100%;
    justify-content: center;

    & > div {
        width: 10px;
        height: 10px;
        margin: 5px;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        border: 1px solid $spinner-dot-border-color;

        &:nth-child(1) {
            animation-delay: -0.32s;
        }

        &:nth-child(2) {
            animation-delay: -0.16s;
        }
    }

    &.inverted > div {
        border: 1px solid $spinner-inverted-dot-border-color;
        background: $spinner-inverted-bg;
    }
}
@keyframes sk-bouncedelay {
    0%,
    100%,
    80% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}
